/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

:root {
  --header-color: #fff;
  --background-img: "";
}

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~ngx-toastr/toastr";
@import "~ngx-toastr/toastr-bs4-alert";

// Theme customization
@import "theme/theme";

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: var(--textFontFamily, "Montserrat"), sans-serif;
  color: #393939;

  @media only screen and (max-width: 768px) {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.toast-top-right {
  top: 50px !important;
  right: 8px !important;
}

.ngx-toastr.toast-error {
  color: #8e5858;
}

.ngx-toastr.toast-info {
  color: #58588e;
}

.ngx-toastr.toast-success {
  color: #5a8e58;
}

.ngx-toastr.toast-warning {
  color: #856404;
}

@import "theme/bootstrap-colors-override";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

/*
  * Global CSS is the only way to apply CSS to the map-window-info component of @angular/google-maps
  * the rules applied are below
  */
.gm-style .gm-style-iw {
  width: 200px;
  max-width: 284px;
}

.map-window-info-title {
  font-family: "Montserrat", sans-serif;
  color: var(--primary);
  font-weight: bold;
  font-size: 12pt;
}

.map-window-info-store-address {
  margin: 10px auto;
  font-family: "Montserrat", sans-serif;
  color: rgb(152, 152, 152);
  text-align: left;
}

.map-window-info-order-button {
  text-align: center;
  text-decoration: none;
  background: var(--secondary);
  cursor: pointer;
  font-size: 12pt;
  width: 100%;
}

.map-window-info-order-button > a {
  text-decoration: none;
  color: var(--white);
  font-weight: bold;
}

$prefixes: "btn", "bg", "alert", "label", "list-group-item", "panel", "progress-bar", "badge";

.form-control:focus {
  border-color: var(--primary);
  box-shadow: none;
  outline: 0;
}

@each $prefix in $prefixes {
  .#{$prefix}-primary {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .badge-primary:hover,
  .btn-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .#{$prefix}-primary:active,
  .#{$prefix}-primary.active,
  .#{$prefix}-primary:focus {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    box-shadow: none !important;
  }

  .#{$prefix}-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
  }

  .#{$prefix}-outline-primary:active,
  .#{$prefix}-outline-primary:focus,
  .#{$prefix}-outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
  }

  .#{$prefix}-secondary {
    background-color: var(--secondary);
  }

  .badge-secondary:hover,
  .btn-secondary:hover {
    background-color: var(--secondary);
  }

  .#{$prefix}-secondary:active,
  .#{$prefix}-secondary:focus {
    background-color: var(--secondary);
  }

  .#{$prefix}-outline-secondary {
    border-color: var(--secondary);
    color: var(--secondary);
  }

  .#{$prefix}-outline-secondary:active,
  .#{$prefix}-outline-secondary:focus,
  .#{$prefix}-outline-secondary:hover {
    background-color: var(--secondary);
    color: var(--white);
  }

  .#{$prefix}-white {
    background-color: var(--white);
  }

  .badge-white:hover,
  .btn-white:hover {
    background-color: var(--white);
  }

  .#{$prefix}-white:active,
  .#{$prefix}-white:focus {
    background-color: var(--white);
  }

  .#{$prefix}-outline-white {
    border-color: var(--white);
    color: var(--white);
  }

  .#{$prefix}-outline-white:active,
  .#{$prefix}-outline-white:focus,
  .#{$prefix}-outline-white:hover {
    background-color: var(--white);
    color: var(--white);
  }

  .#{$prefix}-black {
    background-color: var(--black);
  }

  .badge-black:hover,
  .btn-black:hover {
    background-color: var(--black);
  }

  .#{$prefix}-black:active,
  .#{$prefix}-black:focus {
    background-color: var(--black);
  }

  .#{$prefix}-outline-black {
    border-color: var(--black);
    color: var(--black);
  }

  .#{$prefix}-outline-black:active,
  .#{$prefix}-outline-black:focus,
  .#{$prefix}-outline-black:hover {
    background-color: var(--black);
    color: var(--black);
  }

  .#{$prefix}-light {
    background-color: var(--light);
  }

  .badge-light:hover,
  .btn-light:hover {
    background-color: var(--light);
  }

  .#{$prefix}-light:active,
  .#{$prefix}-light:focus {
    background-color: var(--light);
  }

  .#{$prefix}-outline-light {
    border-color: var(--light);
    color: var(--light);
  }

  .#{$prefix}-outline-light:active,
  .#{$prefix}-outline-light:focus,
  .#{$prefix}-outline-light:hover {
    background-color: var(--light);
    color: var(--light);
  }

  .#{$prefix}-dark {
    background-color: var(--dark);
  }

  .badge-dark:hover,
  .btn-dark:hover {
    background-color: var(--dark);
  }

  .#{$prefix}-dark:active,
  .#{$prefix}-dark:focus {
    background-color: var(--dark);
  }

  .#{$prefix}-outline-dark {
    border-color: var(--dark);
    color: var(--dark);
  }

  .#{$prefix}-outline-dark:active,
  .#{$prefix}-outline-dark:focus,
  .#{$prefix}-outline-dark:hover {
    background-color: var(--dark);
    color: var(--light);
  }
}
